import Home from './components/Home.vue'
import AboutUs from './components/Aboutus.vue'
import Principles from './components/Principles.vue'
import WhatWeDo from './components/WhatWeDo.vue'
import Handwerk from './components/Handwerk.vue'
import UserDriven from './components/UserDriven.vue'
import DotNet from './components/DotNet.vue'
import SoftwareDev from './components/SoftwareDev.vue'
import Jobs from './components/Jobs.vue'
import JobDotNetDeveloper from './components/JobDotNetDeveloper.vue'
import JobFullStackDeveloper from './components/JobFullStackDeveloper.vue'
import Werkstudent from './components/Werkstudent.vue'
import ProjectManager from './components/JobProjectManager.vue'
import Contact from './components/Contact.vue'
import Legal from './components/Legal.vue'
import PrivacyPolicy from './components/PrivacyPolicy.vue'
import NotFound from './components/NotFound.vue'
import NewWork from './components/NewWork.vue'
import ItsAMatch from './components/ItsAMatch.vue'
import Experten from './components/Experten.vue'
import ChangeManagement from './components/ChangeManagement.vue'
import LessonsLearned from './components/LessonsLearned.vue'
import ArbeitsplatzDerZukunft from './components/ArbeitsplatzDerZukunft.vue'

const routes = [
  { path: '/', name: 'home', component: Home, meta: { title: 'Willkommen bei SOFTWERKSTATT', desc: 'Eure Digitalagentur für Softwareentwicklung - wir entwickeln Websites, Apps für und Individualsoftware als Internetagentur und Dienstleister. » Jetzt informieren!' } },
  
  { path: '/so-geht-digitalisierung', name: 'whatwedo', component: WhatWeDo, meta: { title: 'So geht Digitalisierung - Softwerkstatt Hamburg', desc: 'Hamburgs Digitalisierungspartner - wir programmieren .NET Backend, Web & Mobile Apps. So digitalisieren wir Euer Business. » JETZT DIGITALISIEREN!' } },
  { path: '/change-management-hamburg', name: 'changemanagement', component: ChangeManagement, meta: { title: 'ChangeManagement', desc: '' } },
  { path: '/arbeitsplatz-der-zukunft', name: 'arbeitsplatz', component: ArbeitsplatzDerZukunft, meta: { title: 'Arbeitsplatz der Zukunft', desc: '' } },
  { path: '/lessons-learned-best-practices', name: 'lessonslearned', component: LessonsLearned, meta: { title: 'Best Practices 6 Lessons Learned - SOFTWERKSTATT teilt das Wissen', desc: 'Wir glauben daran, dass unsere Kunden nicht die Fehler Anderer wiederholen sollten. Darum möchten wir so gut es geht unsere Learnings, Wissen und Know-How teilen.' } },

  { path: '/individualsoftware-hamburg', name: 'softwaredev', component: SoftwareDev, meta: { title: 'Individualsoftware - Softwerkstatt Internetagentur Hamburg', desc: 'Maßgefertigte Software für Euer Unternehmen: Wir entwickeln nutzerfreundliche Softwarelösungen. Sicher und datenschutzkonform.' } },
  { path: '/csharp-und-.net-entwicklung', name: 'dotnet', component: DotNet, meta: { title: 'C# und .NET 8 Entwicklung - Softwerkstatt Softwareentwicklung', desc: 'C# und .NET sind etablierte Technologien für die Programmierung mit dem Microsoft Tech-Stack, um stabile Enterprise-Anwendungen zu programmieren.' } },
  { path: '/externe-experten', name: 'externeexperten', component: Experten, meta: { title: 'Freelancer &amp; Externe Experten', desc: '' } },

  { path: '/wer-wir-sind', name: 'aboutus', component: AboutUs, meta: { title: 'Das sind wir | Softwerkstatt | Entwicklung von Individualsoftware', desc: 'Als Digitalagentur bieten wir Programmierung von Individualsoftware wie Websites und mobile Apps. Gerne digitalisieren wir Eure Ideen » Made in Hamburg.' } },
  { path: '/handwerk', name: 'handwerk', component: Handwerk, meta: { title: 'Unser Handwerk | Softwerkstatt Digitalagentur', desc: 'Wir leben Software Craftsmanship und lieben Qualität durch sauberes Handwerk - Euer Softwareentwicklungs-Partner und Dienstleister in Hamburg.' } },
  { path: '/unsere-vision', name: 'principles', component: Principles, meta: { title: 'Unsere Vision - für Eure Softwareentwicklung', desc: 'Wir lieben Technologie, Software Consulting und Herausforderungen. Für Programmierung und Webentwicklung schlägt unser Herz. Made in Hamburg.' } },
  { path: '/user-driven-development', name: 'userdriven', component: UserDriven, meta: { title: 'Nutzergetriebene Softwareentwicklung in Hamburg erleben', desc: 'Wir lieben User-Fokus und Feedback-getriebene Entwicklung. Softwerkstatt aus Hamburg.' } },
  { path: '/new-work', name: 'newwork', component: NewWork, meta: { title: 'Unsere Firmenkultur als New Work', desc: 'New Work' } },
  { path: '/its-a-match', name: 'itsamatch', component: ItsAMatch, meta: { title: 'It’s a match! ', desc: 'It’s a match! ' } },
  { path: '/jobs', name: 'jobs', component: Jobs, meta: { title: 'Jobs bei Softwerkstatt | Softwareenwicklung in Hamburg', desc: 'Werde als Software-Entwickler/in Teil der Hamburger Softwerkstatt. Wir freuen uns darauf, Dich persönlich kennenzulernen. » Jetzt bewerben!' } },
  { path: '/jobs/fullstack-entwickler-hamburg', name: 'fullstackdev', component: JobFullStackDeveloper, meta: { title: 'Full-Stack Entwickler/in @ Softwerkstatt Hamburg', desc: 'Werde Full Stack Entwickler/in bei Softwerkstatt in Hamburg! Wir freuen uns darauf, Dich persönlich kennenzulernen. » Jetzt Bewerben!' } },
  { path: '/jobs/dotnet-entwickler-hamburg', name: 'dotnetdev', component: JobDotNetDeveloper, meta: { title: 'C#/.NET Entwickler/in Hamburg | Softwerkstatt | Software Entwicklung', desc: 'Werde .NET Entwickler/in bei Softwerkstatt in Hamburg! Wir freuen uns darauf, Dich persönlich kennenzulernen. » Jetzt Bewerben!' } },
  { path: '/jobs/werkstudent-hr-recruiting-operations-in-hamburg', name: 'werkstudent', component: Werkstudent, meta: { title: 'Werkstudent Business Development & Operations bei Softwerkstatt', desc: 'Werde Werkstudent/in bei Softwerkstatt in Hamburg! Wir freuen uns darauf, Dich persönlich kennenzulernen. » Jetzt Bewerben!' } },
  { path: '/jobs/projektmanager-hamburg', name: 'pm', component: ProjectManager, meta: { title: 'Vertriebs- & Projektmanager/in Hamburg in Hamburg', desc: 'Werde Projektmanager/in in Hamburg! Wir freuen uns drauf, Dich persönlich kennenzulernen. » Jetzt Bewerben!' } },
  { path: '/kontakt', name: 'contact', component: Contact, meta: { title: 'Kontakt | Softwerkstatt Digitalagentur', desc: 'Kontakt zur Hamburger Softwerkstatt. Euer Software-Consulting Partner für Digitalisierung. » Jetzt kennenlernen und gemeinsam in See stechen! «' } },

  { path: '/impressum', name: 'legal', component: Legal, meta: { title: 'Impressum - Softwerkstatt', desc: 'Softwerkstatt GmbH. Geschäftsführer: Dario D. Müller' } },
  { path: '/privacy-policy', name: 'privacy', component: PrivacyPolicy, meta: { title: 'Datenschutzerklärung - Softwerkstatt Internetagentur', desc: 'Datenschutzerklärung der Hamburger Softwerkstatt' } },
  { path: '/x:trackingId', name: 'home2', component: Home, meta: { title: 'Softwerkstatt - Eure Digitalagentur für Softwareentwicklung', desc: 'Wir entwickeln Websites und Apps für Eure digitalen Prozesse und Geschäftsmodelle und programmieren Individualsoftware als Internetagentur und Dienstleister.' } },
  { path: "/:catchAll(.*)", name: 'notfound', component: NotFound, meta: { title: 'Not Found - Softwerkstatt ', desc: 'Seite Leider nicht gefunden - Softwerkstatt aus Hamburg' } },
]

export default routes