<template>
  <div class="container mx-auto h-screen max-w-screen-lg 2xl:max-w-screen-xl pt-16 px-4 lg:px-0 text-center">
    <span class="block pre-headline pt-32">404 Not Found</span>
    <h1 class="h1">Kein Land in Sicht</h1>
    <img class="mx-auto h-64" src="./../assets/img/howto/3.png" alt="">
    <p>Leider hat sich unser Schiff auf der hoher See verfahren.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>